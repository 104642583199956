.DetailTableRow {
    margin-top: 15px;
    margin-bottom: 10px;
}

.DetailTableLabel {
    font-size: large;
    font-weight: 700;
    margin-left: 10px;
    display: inline-block;
    width: 180px;
}

.DetailTableData {
    display: inline-block;
    padding-left: 15px;
    padding-right: 100px;
    padding-top: 5px;
    padding-bottom: 5px;
    background: rgb(192, 190, 190);
    font-size:large;
    font-weight: 500;
    border-radius: 25px;
    border: gray 1px solid;
    width: 600px;
    min-height: 30px;
}

.img-wrapper {
    overflow: hidden;
}

/*img.hover-zoom {
    transition: all 0.3s ease-in-out 0s;
}
img.hover-zoom:hover {
    transform: scale(4);
}*/
